<!-- eslint-disable no-unused-vars -->
<template>
  <v-container>
    <v-row class="mt-3">
    <v-flex  x12 md4>
    <v-card
    outlined>
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
        color="brown">
        <v-icon large color="white">mdi-home</v-icon>
    </v-list-item-avatar>
      <v-list-item-content>
      
        <v-list-item-title class="text-h5 mb-1">
          Planned
        </v-list-item-title >
        <span class="brown pa-2 white--text" style="size:20px"  v-for="p in plan" :key="p.count">{{p.count}}</span>
       
      </v-list-item-content>      
    </v-list-item>
  </v-card>
</v-flex>
<v-flex  x12 md4 >
    <v-card
    outlined>
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
        color="brown">
        <v-icon large color="white">mdi-home</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      
      <v-list-item-title class="text-h5 mb-1">
        Completed
      </v-list-item-title >
      <span class="brown pa-2 white--text" style="size:20px"  v-for="c in comp" :key="c.count">{{c.count}}</span>
     
    </v-list-item-content>     
    </v-list-item>
  </v-card>
</v-flex>
<v-flex  x12 md4>
    <v-card
    outlined>
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
        color="brown">
        <v-icon large color="white">mdi-home</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      
      <v-list-item-title class="text-h5 mb-1">
        Employee
      </v-list-item-title >
      <span class="brown pa-2 white--text" style="size:20px" v-for="e in emp" :key="e.count">{{e.count}}</span>
     
    </v-list-item-content>     
    </v-list-item>
  </v-card>
</v-flex>

<v-flex  x12 md12 class="mt-4">
    <v-card
    outlined>
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
        color="brown">
        <v-icon large color="white">mdi-home</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      
      <v-list-item-title class="text-h5 mb-1">
        Gant Chart
      </v-list-item-title >
      <FullCalendar class="brown--text" :options="calendarOptions"/>
    </v-list-item-content>     
    </v-list-item>
  </v-card>
</v-flex>
</v-row>
</v-container>
</template>

<script type="text/javascript">
  
 
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import axios from 'axios'
var url = 'https://jjssystem.com/workplan/crud.php';

export default {
 
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },  data () {
    return {
      events:[],
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin,timeGridPlugin,listPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,listDay'
        },
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        events: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        // events: [{id:1,title:'event 1',start:'2022-09-03',end:'2022-08-20', allDay:true}],
      },
      currentEvents: [],
      multiLine:true,
      text:'',
      snackbar:false,
      attend: '',
      items: ['Present', 'Absent', 'Premission'],
      plan: [],
      comp: [],
      emp: [],
      opcion: '',
      bystudent: [],
      batid: 0,
      coid: 0,
      tijabo:'',
      sname:[]
    }
  

},
     
  mounted (){
    this.byplan()
    this.bycomplete()
    this.byemploye()
    this.bycalendar();
    //this.autoload()  
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    handleDateSelect(selectInfo) {
      let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
    },

    handleEventClick(clickInfo) {
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }
    },

    handleEvents(events) {
      this.currentEvents = events
    },
 handleDateClick: function(arg) {
      alert('date click! ' + arg.dateStr)
    },
    byplan: function () {
      axios.post(url, { opcion: 6 })
        .then(response => {
          this.plan = response.data
           console.log(this.plan)
        }).catch(error => {
          console.log(error)
        })
    },
    bycalendar: function () {
      axios.post('https://jjssystem.com/workplan/action.php')
        .then(response => {
        this.calendarOptions.events= response.data
           //console.log(this.events)
        }).catch(error => {
          console.log(error)
        })
    },
    bycomplete: function () {
      axios.post(url, { opcion: 7 })
        .then(response => {
          this.comp = response.data
        }).catch(error => {
          console.log(error)
        })
    },
    byemploye: function () {
      axios.post(url, { opcion: 8 })
        .then(response => {
          this.emp = response.data
        }).catch(error => {
          console.log(error)
        })
    }

}
}
</script>