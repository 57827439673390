<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :search="search"
      :items="emp"
      sort-by="calories"
      class="elevation-1"
      :loading="loadTable"
       loading-text="Loading...."
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Employees Registration</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field class="mt-8 ml-7" outlined v-model="search" placeholder="Search......."></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="newdata"
              >
                New employees
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                    <v-input
                        v-model="employees.id"
                        type="hidden"
                      
                      ></v-input>
                  <v-row>
                  
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="employees.name"
                        label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="employees.email"
                        label="email"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="employees.username"
                        label="Username"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="employees.password"
                        label="Password"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6">
                      <v-select
                      v-model="employees.utype"
                      :items="items"
                      label="Standard"
                      dense
                    ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="kushubo"
                >
                  {{oper}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="editItem(item.id,item.full_name,item.email,item.user_name,item.password,item.user_type)"
>
        <v-icon
          small
          class="mr-2"
        >
          mdi-pencil
        </v-icon>
    </v-btn>
    <v-btn @click="deleteItem(item.id)">
        <v-icon
          small
        >
          mdi-delete
        </v-icon>
    </v-btn>
      </template>
      <template v-slot:no-data>
      
      </template>
      
    
    </v-data-table>


    <v-snackbar
      v-model="snackbar"
      right
      color="brown"
    >
      {{ text }}
    </v-snackbar>
  </v-container>
  </template>
  <script>
    import axios from 'axios'
    var url = 'http://localhost:88/workplan/crud.php';


    export default {
      data: () => ({
        loadTable: true,
        items: ['Admin', 'User'],
        snackbar: false,
      text: `Hello, I'm a snackbar`,
        search:'',
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            class:'brown white--text',
            value: 'id',
          },
          { text: 'Name', value: 'full_name',class:'brown white--text' },
          { text: 'Email', value: 'email',class:'brown white--text' },
          { text: 'Username', value: 'user_name' ,class:'brown white--text'},
          { text: 'Password', value: 'password' ,class:'brown white--text'},
          {text: 'User type', value:'user_type',class:'brown white--text'},
          { text: 'Actions', value: 'actions', sortable: false,class:'brown white--text' },
        ],
        desserts: [],
        emp:[],
        employees:{
            id:0,
            name:'',
            email:'',
            username:'',
            password:'',
            utype:''
        },
        opcion:'',
        editedIndex: -1,
        editedItem: {
          name: '',
          calories: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        defaultItem: {
          name: '',
          calories: 0,
          fat: 0,
          carbs: 0,
          protein: 0,
        },
        op:0,
        oper:'',
        operation:'',
      }),
  
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'New Employee' : 'Edit Employee'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
       
        this.employee();
      },
  
      methods: {
        employee: function () {
            this.op=0;
      axios.post(url, { opcion: 1 ,op:this.op})
        .then(response => {
          this.emp = response.data
           console.log(this.emp)
           this.loadTable= false;
        }).catch(error => {
          console.log(error)
        })
    },

        insert: function(){
            
        this.op=1;
      axios.post(url, { opcion: 1 ,op:this.op,name:this.employees.name,email:this.employees.email,username:this.employees.username,password:this.employees.password,utype:this.employees.utype})
        .then(response => {
           console.log( response.data);
           this.employee();
           this.text='Insert Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        });
        this.dialog=false;
        },
        update: function(){
            this.op=11;
      axios.post(url, { opcion: 1, id:this.employees.id,op:this.op,name:this.employees.name,email:this.employees.email,username:this.employees.username,password:this.employees.password,utype:this.employees.utype})
        .then(response => {
           console.log( response.data);
           this.employee();
           this.text='Updated Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        })
        this.dialog=false;
        },
        kushubo:function(){
           if(this.operation=='insert'){
            this.insert();
           }else if(this.operation=='edit'){
            this.update();
           } 
        },
        delete: function(){

        },
        newdata:function(){
            this.operation='insert';
            this.oper='save';
            this.employees.name=''
            this.employees.email=''
            this.employees.username=''
            this.employees.password=''
            this.employees.utype=''
        },
       
        editItem (id,name,email,username,password,utype) {
            this.operation='edit';
            this.oper='Update';
            this.employees.id=id;
            this.employees.name=name;
            this.employees.email=email;
            this.employees.username=username;
            this.employees.password=password;
            this.employees.utype=utype;
            console.log(id);
         
          this.dialog = true
        },
  
        deleteItem :function(id) {
            this.op=12;
      axios.post(url, { opcion: 1, id:id,op:this.op})
        .then(response => {
          //   console.log(id);
          //  console.log( response.data);
           this.employee();
           this.text='Deleted Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        })
        //   this.editedIndex = this.desserts.indexOf(item)
        //   this.editedItem = Object.assign({}, item)
          this.dialogDelete = true
        },
  
        deleteItemConfirm () {
          this.desserts.splice(this.editedIndex, 1)
          this.closeDelete()
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        save () {
          if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
          } else {
            this.desserts.push(this.editedItem)
          }
          this.close()
        },
      },
    }
  </script>