<!-- eslint-disable space-before-blocks -->
<template>
    <v-app id="inspire">
          <v-container fluid fill-height>
             <v-layout align-center justify-center>
                <v-flex xs12 sm8 md4>
                  <h1 class="mb-11 brown white--text" style="text-align:center; font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;">MOIFAR JSS Workplan</h1>

                   <v-card class="elevation-12">
                   <v-alert
                      elevation="2"
                      border="bottom"
                      class="brown white--text"
                      transition="fab-transition"
                      dark
                      v-if="alert">
                      {{message}}
                   </v-alert>
                      <v-toolbar dark color="brown">
                         <v-toolbar-title>Log In Form</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                         <v-form>
                            <v-text-field
                               prepend-icon="mdi-account"
                               name="login"
                               v-model="datalogin.username"
                               label="Username"
                               type="text"
                            ></v-text-field>
                            <v-text-field
                               id="password"
                               prepend-icon="mdi-lock"
                               v-model="datalogin.password"
                               name="password"
                               label="Password"
                            :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'password' : 'text'"
                            ></v-text-field>
                         </v-form>
                      </v-card-text>
                      <v-card-actions>
                         <v-spacer></v-spacer>
                         <v-btn color="brown white--text" @click="login">Log In</v-btn>
                      </v-card-actions>
                   </v-card>
                </v-flex>
             </v-layout>
          </v-container>

    </v-app>
 </template>
<script>
import axios from 'axios'
const url = 'http://localhost:88/workplan/crud.php'
export default {
  data () {
    return {
      op: 0,
      value: String,
      datalogins: [],
      datalogin: {
        username: '',
        password: ''
      },
      msg: '',
      alert: false,
      message: '',
      opcion: ''
    }
  },
  methods: {
    login: function () {
      console.log("hhhhh")
      this.op = 20
      axios.post(url, { opcion: 20, username: this.datalogin.username, password: this.datalogin.password, op: this.op })
        .then(response => {
          this.datalogins = response.data
          console.log(this.datalogins)
          this.alert = true
          if (response.data[0].msg == 'fill') {
            this.message = 'Fill in the Blank Space'
            setTimeout(() => {
              this.alert = false
            }, 2000)
          } else if (response.data[0].msg == 'wrong') {
            this.message = 'UserName or Password Is Incorrect'
            setTimeout(() => {
              this.alert = false
            }, 2000)
          } else {
            sessionStorage.setItem('ID', response.data[0].id)
            sessionStorage.setItem('utype', response.data[0].user_type)
            sessionStorage.setItem('status', true)
            this.$emit('authenticated', true)
            console.log(response.data)
            this.$router.replace({ name: 'HomeView' })
          }
        }).catch(error => {
          console.log(error)
        })
    }
  },
  mounted () {
    this.$emit('authenticated', false)
  }
}
</script>

 <style></style>
