<template>
  <nav>
      <v-app-bar color="brown" class="flex-grow-0" app dark>
        
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
       <span><img src="../assets/jowhar.png" width="50"></span> <div class="ml-16">  <span>Ministry of Interior, Fedaralism and Reconciliation</span></div>

        <v-spacer></v-spacer>
 
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on" @click="logout" >
            <v-icon>mdi-power</v-icon>
          </v-btn>
        </template>

      </v-menu>
      </v-app-bar>
      
      
    
    <v-navigation-drawer app v-model="drawer" color="brown" class="">
        <v-list-item  class="white--text">
          <v-list-item-content>
            <v-list-item-title class="text-h6"> WorkPlan</v-list-item-title>
            <v-list-item-subtitle  class="white--text"> Moifar JSS</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav class="brown red--text">
          <v-list-item v-for="item in items" v-show="item.show" :key="item.title" router :to="item.path">
            <v-list-item-icon  >
              <v-icon class="white--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title  class="white--text">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </nav>
  </template>
  
  <script>
  export default {
    name: 'App',
    data: () => ({
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard',path:'/',show:true},
        { title: 'Employee Registration', icon: 'mdi-account-box',path:'/employee' ,show:true},
        { title: 'Workplan', icon: 'mdi-cog',path:'/workplan',show:true },
        { title: 'Execute', icon: 'mdi-cog',path:'/assign',show:true },
        { title: 'Report', icon: 'mdi-home',path:'/report' ,show:true},
      ],
    }),
    created(){
      this.userlogo();
    },
    methods:{
      userlogo:function(){
        if(sessionStorage.getItem("utype")=='Admin'){
          this.items[0].show=true;
          this.items[1].show=true;
          this.items[2].show=true;
          this.items[3].show=true;
          this.items[4].show=true;
        }else if(sessionStorage.getItem("utype")=='User'){
          this.$router.push({ name: 'userdisplay' })
          this.items[0].show=false;
          this.items[1].show=false;
          this.items[2].show=false;
          this.items[3].show=false;
          this.items[4].show=false;
        }
      },
      logout(){
        this.authenticated=true;
        sessionStorage.removeItem('status');
        sessionStorage.removeItem('ID')
        sessionStorage.removeItem('utype');
        this.$router.push('/login');
      }
    }
  };
  </script>