<template>
    <div>
      <v-app-bar
        color="brown "
        dense
        dark
      >
      <v-btn color="brown">
        <v-icon>mdi-plus</v-icon>
     </v-btn>
        <v-toolbar-title class="ml-7">MOIFAR</v-toolbar-title>
        <v-spacer></v-spacer>
          <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="logout" 
            >
              <v-icon>mdi-power</v-icon>
            </v-btn>
          </template>
  
        </v-menu>
      </v-app-bar>
    </div>
  </template>

  <script>
    export default{
        data(){
            return{
                
            }
        },
        methods:{
          logout(){
        this.authenticated=true;
        sessionStorage.removeItem('ID');
        sessionStorage.removeItem('status');
        sessionStorage.removeItem('utype');
        this.$router.push('/login');
      }
        }
    }
  </script>