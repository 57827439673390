<template>
    <div>
      <v-card class="mt-4 ml-2" width="1200">
    <v-tabs
      v-model="tab"
      background-color="brown"
      dark
    >
    <v-tab class="ml-5">
        *******************************************************************************************************************************************************************************************************************************************************
      </v-tab>
  
      <v-tab-item class="pa-5">
        <v-row>
          <v-layout class="mt-2 ml-2">
      <v-flex float xs12  md12>
          <v-card >
            <v-card-title></v-card-title>
            <v-card-text>
            <v-row class="brown">
              <v-col md12>
          <v-btn width="300" class="brown white--text" rounded  @click="allacounts">
          All Employees
        </v-btn>
      </v-col>
      <v-col md12>
        <v-btn width="300" class="brown white--text" rounded @click="allemployee">
          Workplan
        </v-btn>
      </v-col>
      </v-row>
      <v-row class="brown mt-5 ">
        <v-col style="margin-left:250px;">
          <v-btn width="500"  rounded class="brown white--text" @click="payroll">
          Executed
        </v-btn>
      </v-col>
     
      </v-row>
      <!-- <v-row class="brown mt-5 ">
        <v-col cols="12" sm="6" md="4">
       <v-select class="brown white--text"></v-select>
       <v-btn>Submit</v-btn>
       </v-col>
       <v-col cols="12" sm="6" md="4">
       <input type="date"><input type="date">
       </v-col>
       <v-col cols="12" sm="6" md="4">
       <v-select class="brown white--text"></v-select>
       </v-col>
      </v-row> -->
            </v-card-text>
          </v-card>
    </v-flex>
    
    
    </v-layout>
          
          
      </v-row>
      </v-tab-item>
     
    </v-tabs>
    
  </v-card>
  <v-row justify="center">


<v-dialog
  v-model="dialog"
  persistent
  max-width="1100">
  <v-card>
    <v-card-title class="text-h5">
     <span>{{title}}</span>
     <v-btn
        color="green darken-1 ml-20"
        text
        @click="closef"
      ><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
<v-divider></v-divider>

<v-btn class="brown white--text ml-2" @click="print">Print</v-btn>
<v-btn class="brown white--text ml-2" @click="exportexcel">Export to excel</v-btn>
    <v-card-text class="mt-2">
    
      <v-text-field v-model="search" placeholder="search">
      </v-text-field>
      <div id="print">
        <v-card>
        <div class="mt-2 text-center pa-3">
      
      <h1 style="text-align:center">Jubbaland Moifar</h1>
    </div>
    </v-card>
      <v-data-table
      style="text-align:center"
  :headers="headers"
  :items="allfields"
  :search="search"
  class="elevation-1 mt-2">
  <template v-slot:item.upload_document="{ item }">
       <a :href="(`localhost/workplan/upload/${item.upload_document}`)">{{item.upload_document}}</a>
  </template> 
</v-data-table>
    </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        text
        @click="closef"
      >Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</v-row>
 </div>
  </template>
  <script>
    import axios from 'axios';

var url = "http://localhost:88/workplan/crud.php";


  export default {
    name:'App',
  
    data () {
      return {
        search:'',
        title:'',
        tab: null,
        allfields:[],
        dialog: false,
        opcion:'',
        headers:[
              {text: "ID", value:"id", class:'brown white--text',status:true,sortable:false},
           //employees
            { text: 'full_name', value: 'full_name', class:'brown white--text',sortable:false },
            { text: 'email', value: 'email', class:'brown white--text' ,sortable:false },
            { text: 'user name', value: 'user_name', class:'brown white--text',sortable:false },
            { text: 'password', value: 'password', class:'brown white--text',sortable:false },
            {text: 'user type', value:'user_type', class:'brown white--text',sortable:false},
              //workplan
            {text: 'serial_number', value: 'serial_number', class:'brown white--text',sortable:false},
            {text: 'Workplan', value: 'work_name', class:'brown white--text',sortable:false},
            {text: 'description', value: 'description', class:'brown white--text',sortable:false},
            {text: 'status', value: 'status', class:'brown white--text',sortable:false},
            {text: 'assigned', value: 'assigned', class:'brown white--text',sortable:false},
            {text: 'started', value:'started', class:'brown white--text',sortable:false},
            {text: 'ended', value:'ended', class:'brown white--text',sortable:false},
            {text: 'Expected Beneficiaries', value:'expected', class:'brown white--text',sortable:false},
              //execute
            { text: 'Workplan', value: 'work_name', class:'brown white--text',sortable:false },
            { text: 'Description', value: 'description' , class:'brown white--text',sortable:false},
            { text: 'Status', value: 'status' , class:'brown white--text',sortable:false},
            { text: 'Timeline', value: 'timeline', class:'brown white--text',sortable:false },
            {text: 'Assigned', value:'full_name', class:'brown white--text',sortable:false},
            {text: 'Report', value:'upload_document', class:'brown white--text',sortable:false},
            {text: 'Date', value:'date', class:'brown white--text',sortable:false}, 
            ],
            authenticated:JSON.parse(sessionStorage.getItem('status'))
      }
    },
    mounted(){
      if(!this.authenticated){
        this.$router.replace({name:'login'});
      }
    },
    created(){
   
    },
    methods:{
      closef:function(){
        this.dialog=false;
        location.reload();
      },
     allacounts:function(){
     
      this.headers.splice(6,19);
      this.op=1;
              axios.post(url,{opcion:9,op:this.op})
              .then(response =>{
                this.allfields = response.data;  
                console.log(this.allfields);                 
              });
      this.title='All employee'
      this.dialog=true;
     },
     allemployee:function(){
      this.headers.splice(13,19);
      this.headers.splice(1,5);
      this.op=2;
              axios.post(url,{opcion:9,op:this.op})
              .then(response =>{
                this.allfields = response.data;  
                console.log(this.allfields);                 
              });
      this.title="Workplan"
      this.dialog=true;
     },
     payroll:function(){
      this.headers.splice(1,13);
    //   this.headers.splice(1,10);
      this.op=3;
              axios.post(url,{opcion:9,op:this.op})
              .then(response =>{
                this.allfields = response.data;  
                console.log(this.allfields);                 
              });
      this.title="Execute"
      this.dialog=true;
     },
    //  donaters:function(){
    //   this.headers.splice(21,48);
    //   this.headers.splice(1,14);
    //   this.op=4;
    //           axios.post(url,{opcion:4,op:this.op})
    //           .then(response =>{
    //             this.allfields = response.data;  
    //             console.log(this.allfields);                 
    //           });
    //   this.title="Donaters"
    //   this.dialog=true;
    //  },
    //  branche:function(){
    //   this.headers.splice(27,48);
    //   this.headers.splice(1,20);
    //   this.op=5;
    //           axios.post(url,{opcion:4,op:this.op})
    //           .then(response =>{
    //             this.allfields = response.data;  
    //             console.log(this.allfields);                 
    //           });
    //   this.title="All Branches"
    //   this.dialog=true;
    //  },
    //  asset:function(){
    //   this.headers.splice(33,48);
    //   this.headers.splice(1,27);
    //   this.op=6;
    //           axios.post(url,{opcion:4,op:this.op})
    //           .then(response =>{
    //             this.allfields = response.data;  
    //             console.log(this.allfields);                 
    //           });
    //   this.title="All Assets"
    //   this.dialog=true;
    //  },
    //  member:function(){
    //   this.headers.splice(39,48);
    //   this.headers.splice(1,32);
    //   this.op=7;
    //           axios.post(url,{opcion:4,op:this.op})
    //           .then(response =>{
    //             this.allfields = response.data;  
    //             console.log(this.allfields);                 
    //           });
    //   this.title="All members"
    //   this.dialog=true;
    //  },
    //  income:function(){
    //   this.headers.splice(44,48);
    //   this.headers.splice(1,38);
    //   this.op=8;
    //           axios.post(url,{opcion:4,op:this.op})
    //           .then(response =>{
    //             this.allfields = response.data;  
    //             console.log(this.allfields);                 
    //           });
    //   this.title="Income"
    //   this.dialog=true;
    //  },
    //  expayment:function(){
    //  // this.headers.splice(43,48);
    //   this.headers.splice(1,43);
    //   this.op=9;
    //           axios.post(url,{opcion:4,op:this.op})
    //           .then(response =>{
    //             this.allfields = response.data;  
    //             console.log(this.allfields);                 
    //           });
    //   this.title="Expence payment"
    //   this.dialog=true;
    //  },
     
     print: function(){
      const prtHtml = document.getElementById('print').innerHTML;
        // Get all stylesheets HTML
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }

        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();

    },
  exportexcel: function(){
      var uri = 'data:application/vnd.ms-excel;base64,'
        , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>'
        , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
        , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
        var name="worksheet 1";
       var table = document.getElementById("print")
        var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}
        window.location.href = uri + base64(format(template, ctx))

     }
    }
  }
</script>