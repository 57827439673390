<template>
    <v-container>
      
<v-dialog
  v-model="dialog"
  max-width="1100">
  <v-card>
    <v-card-title class="text-h5">
     <span>{{title}}</span>
     <v-btn
        color="green darken-1 ml-20"
        text
    @click="dialog=false"
      ><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
<v-divider></v-divider>

 <v-card-text class="mt-2">
    
      <v-text-field v-model="search" placeholder="search">
      </v-text-field>
      <div id="print">
        <v-card>
        <div class="mt-2 text-center pa-3">
      
      <h1 style="text-align:center">Jubbaland Moifar</h1>
    </div>
    </v-card>
      <v-data-table
      style="text-align:center"
  :headers="head"
  :items="allfields"
  :search="search"
  class="elevation-1 mt-2">

</v-data-table>
    </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green darken-1"
        text
        @click="dialog=false"
      >Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
      <v-btn class="mt-10 mx-auto" @click="allworkplan">View Others</v-btn>
        <v-data-table
      :headers="headers"
      :items="workp"
      sort-by="calories"
      class="elevation-1">   
      <template v-slot:item.report="{ item }">
        <form>
        <input type="file" name="file" id="imageUploade" @change="onFilechange" :maxFileSize="10000" ref="file"/> 
      </form>
      </template> 
     <template v-slot:item.actions="{ item }">
        <v-btn @click="editItem(item.id,item.status,item.assigned)">
        Complete
      </v-btn>
      </template>
      
    
    </v-data-table>
    
    <v-snackbar
      v-model="snackbar"
      right
      color="brown"
    >
      {{ text }}
    </v-snackbar>


    </v-container>
  
  </template>
<script>
    import axios from 'axios'
    var url = 'http://localhost:88/workplan/crud.php'
    var test="http://localhost:88/workplan/upload.php";
    var bashal='';
    export default {
      data () {
        return {
          search:'',
          head:[
          {text: 'serial_number', value: 'serial_number', class:'brown white--text',sortable:false},
          {text: 'Workplan', value: 'work_name', class:'brown white--text',sortable:false},
            {text: 'description', value: 'description', class:'brown white--text',sortable:false},
            {text: 'status', value: 'status', class:'brown white--text',sortable:false},
            {text: 'assigned', value: 'assigned', class:'brown white--text',sortable:false},
            {text: 'started', value:'started', class:'brown white--text',sortable:false},
            {text: 'ended', value:'ended', class:'brown white--text',sortable:false},
            {text: 'Expected Beneficiaries', value:'expected', class:'brown white--text',sortable:false},
          ],
          snackbar: false,
      text: `Hello, I'm a snackbar`,
            headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id'
            ,class:'brown white--text',sortable:false
          },
          {text: 'serial_number', value: 'serial_number', class:'brown white--text',sortable:false},
          {text: 'Workplan', value: 'work_name',class:'brown white--text',sortable:false},
          {text: 'Description', value: 'description',class:'brown white--text',sortable:false},
          {text: 'Status', value: 'status',class:'brown white--text',sortable:false},
          {text: 'Assigned', value: 'assigned',class:'brown white--text',sortable:false},
          {text: 'Started', value:'started',class:'brown white--text',sortable:false},
          {text: 'Ended', value:'ended',class:'brown white--text',sortable:false},
          {text: 'Date', value:'Date',class:'brown white--text',sortable:false},
          {text: 'Report file uplad', value:'report',class:'brown white--text', sortable:false},
          {text: 'Actions', value: 'actions', sortable: false,class:'brown white--text',sortable:false},
        ],
        op:0,
        workp:[],
        ID:sessionStorage.getItem('ID'),
        workplan:{
            id:0,
            w_id:'',
            st:'',
            uploadfile:''
        },
        allfields:[],
        dialog:false,
        title:''
        }
      },
      created(){
        this.display();
        console.log(this.ID)
      },
      methods:{
        allworkplan:function(){
      this.op=2;
              axios.post(url,{opcion:9,op:this.op})
              .then(response =>{
                this.allfields = response.data;  
                console.log(this.allfields);                 
              });
      this.title="Workplan"
      this.dialog=true;
     },
        onFilechange(e){
          //console.log(e);
          
          const file = e.target.files[0];
          var imagefile = document.querySelector('#imageUploade');
          console.log(file);
          console.log(imagefile.files[0]);
          let formData=new FormData();
         formData.append("file",file);
         console.log(formData);
          axios.post(test,formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (response) {
                  bashal=response.data;
                  console.log(bashal);
                    if(!response.data){
                        alert('File not uploaded.');
                    }else{
                    
                        alert('File uploaded successfully.');                        
 
                    }
 
                })
                .catch(function (error) {
                    console.log(error);
                 });
 
        },
        display: function () {
           
      axios.post(url, { opcion: 11 ,id:this.ID})
        .then(response => {
          this.workp = response.data
           console.log(this.workp)
        }).catch(error => {
          console.log(error)
        })
    },
    insert:function(){
      
    },
    editItem (w_id,st,full) {
        st='Completed';
            this.op=1;
            Swal.fire({
  title: 'Are You Sure !',
  showDenyButton: true,
  confirmButtonText: 'Yes',
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
    Swal.fire('Success!', '', 'success')
    axios.post(url, { opcion: 5 ,op:this.op,w_id:w_id,upload:bashal,st:st})
        .then(response => {
           console.log(response.data);
           this.display();
           if(response.data[0].msg == 'wrong'){
           this.text='You have Already Completed this Task MR. '+full;
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 5000);
          }else if(response.data[0].msg == 'insert'){
            this.text='Congrates, Mr. '+full+' You have Completed your Task  ';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 5000);
          }
        }).catch(error => {
          console.log(error)
        });
  }
})
      
        },
      }
    }
  </script>