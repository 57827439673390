<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="emp"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      :loading="loadTable"
       loading-text="Loading...."
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Execute</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field class="mt-8 ml-7" outlined v-model="search" placeholder="Search......."></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="newdata"
              >
                Assign
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                    <v-input
                        v-model="workplan.id"
                        type="hidden"
                      ></v-input>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6">
                      <v-select
                    v-model="workplan.w_id"
                    :items="empoption"
                     item-value="id"
                     item-text="text"
                    @change="empchange"
                    label="Assign"                
                    dense
                    ></v-select>
                    </v-col>
                   
                    <v-col
                      cols="12"
                      sm="6"
                      md="6">
                      <v-text-field
                        v-model="workplan.st"
                        placeholder="Completed"
                        value="Completed"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <input type="file" name="file" id="imageUploade" @change="onFilechange" :maxFileSize="10000" ref="file"/> 
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="kushubo">
                  {{oper}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="editItem(item.id,item.work_name,item.description,item.status,item.emp_id,item.started,item.ended)">
        <v-icon
          small
          class="mr-2">
          mdi-pencil
        </v-icon>
    </v-btn>
    <v-btn @click="deleteItem(item.id)">
        <v-icon
          small>
          mdi-delete
        </v-icon>
    </v-btn>
      </template>
      <template v-slot:no-data>
      
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      right
      color="brown"
    >
      {{ text }}
    </v-snackbar>
    </v-container>
  </template>
  <script>
    import axios from 'axios'
    var url = 'http://localhost:88/workplan/crud.php';
    var test="http://localhost:88/workplan/upload.php";
    var bashal='';

    export default {
      data: () => ({
        loadTable: true,
        snackbar: false,
      text: `Hello, I'm a snackbar`,
        search:'',
        menu2: false,
        menu3:false,
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
            class:'brown white--text'
          },
          { text: 'Workplan', value: 'work_name',class:'brown white--text' },
          { text: 'Description', value: 'description',class:'brown white--text' },
          { text: 'Status', value: 'status',class:'brown white--text' },
          { text: 'Timeline', value: 'timeline',class:'brown white--text' },
          {text: 'Assigned', value:'full_name',class:'brown white--text'},
          { text: 'Actions', value: 'actions', sortable: false,class:'brown white--text' },
        ],
        desserts: [],
        emp:[],
        workplan:{
            id:0,
            w_id:'',
            st:'',
          
        },
        opcion:'',
        editedIndex: -1,
        op:0,
        oper:'',
        operation:'',
        empoption:''
      }),
  
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'New Execute' : 'Edit Employee'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
       
        this.display();
        this.employeeoption();
      },
  
      methods: {
        onFilechange(e){
          //console.log(e);
          
          const file = e.target.files[0];
          var imagefile = document.querySelector('#imageUploade');
          console.log(file);
          console.log(imagefile.files[0]);
          let formData=new FormData();
         formData.append("file",file);
         console.log(formData);
          axios.post(test,formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (response) {
                  bashal=response.data;
                  console.log(bashal);
                    if(!response.data){
                        alert('File not uploaded.');
                    }else{
                    
                        alert('File uploaded successfully.');                        
 
                    }
 
                })
                .catch(function (error) {
                    console.log(error);
                 });
 
        },
        empchange:function(event){
            this.workplan.w_id=event;
            console.log(event);
        },

        employeeoption: function(){
            axios.post(url, { opcion: 4})
        .then(response => {
          this.empoption = response.data
           console.log(this.empoption)
        }).catch(error => {
          console.log(error)
        })
        },
        display: function () {
            this.op=0;
      axios.post(url, { opcion: 5 ,op:this.op})
        .then(response => {
          this.emp = response.data
           console.log(this.emp)
           this.loadTable= false;
        }).catch(error => {
          console.log(error)
        })
    },

        insert: function(){
            this.workplan.st='Completed';
        this.op=1;
      axios.post(url, { opcion: 5 ,op:this.op,w_id:this.workplan.w_id,upload:bashal,st:this.workplan.st})
        .then(response => {
           console.log( response.data);
           this.display();
           this.text='Insert Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        });
        this.dialog=false;
        },
        update: function(){
            this.op=11;
      axios.post(url, { opcion: 5, id:this.workplan.id,op:this.op,w_id:this.workplan.w_id,st:this.workplan.st})
        .then(response => {
           console.log( response.data);
           this.display();
        }).catch(error => {
          console.log(error)
        })
        this.dialog=false;
        },
        kushubo:function(){
           if(this.operation=='insert'){
            this.insert();
           }else if(this.operation=='edit'){
            this.update();
           } 
        },
        delete: function(){

        },
        newdata:function(){
            this.operation='insert';
            this.oper='save';
            this.workplan.w_id=''
            this.workplan.st=''

        },
       
        editItem (id,w_id,st,) {
            this.operation='edit';
            this.oper='Update';
            this.workplan.id=id;
            this.workplan.w_id=w_id;
            this.workplan.st=st;

          this.dialog = true
        },
  
        deleteItem :function(id) {
            this.op=12;
      axios.post(url, { opcion: 5, id:id,op:this.op})
        .then(response => {
            console.log(id);
           console.log( response.data);
           this.display();
        }).catch(error => {
          console.log(error)
        })
        //   this.editedIndex = this.desserts.indexOf(item)
        //   this.editedItem = Object.assign({}, item)
          this.dialogDelete = true
        },
  
        deleteItemConfirm () {
          this.desserts.splice(this.editedIndex, 1)
          this.closeDelete()
        },
  
        close () {
          this.dialog = false
          // this.$nextTick(() => {
          //   this.editedItem = Object.assign({}, this.defaultItem)
          //   this.editedIndex = -1
          // })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        save () {
          if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
          } else {
            this.desserts.push(this.editedItem)
          }
          this.close()
        },
      },
    }
  </script>