import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Swal from 'sweetalert2'
import Select2 from 'v-select2-component';

Vue.component('Select2', Select2);
// CommonJS
window.Swal=Swal;
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
