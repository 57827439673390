<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="emp"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      :loading="loadTable"
       loading-text="Loading...."
    >
    <template v-slot:item.budget="{item}">
      <span>${{item.budget}}</span>
    </template>
      <template v-slot:top>
        <v-toolbar
          flat>
          <v-toolbar-title>WorkPlan</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field class="mt-8 ml-7" outlined v-model="search" placeholder="Search......."></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="newdata"
              >
                New workplan
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
  
              <v-card-text>
                <v-container>
                    <v-input
                        v-model="workplan.id"
                        type="hidden"
                      ></v-input>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="workplan.serial"
                        label="Serial Number"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="workplan.wname"
                        label="Work"
                      ></v-text-field>
                    </v-col>
                 
                    <v-col
                      cols="12"
                      sm="6"
                      md="6" >
                      <v-textarea
                        v-model="workplan.desc"
                        label="Description"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="workplan.st"
                        placeholder="Planned"
                        value="Planned"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                    
                      <v-select
                    v-model="workplan.emp_id"
                    :items="empoption"
                     item-value="id"
                     item-text="text"
                    @change="empchange"
                    label="Assign"                
                    dense
                    
                    ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4">
                      
                     <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="workplan.w_start"
                        label="Started"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="workplan.w_start"
                    @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
    
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <!-- <v-text-field
                        v-model="workplan.w_ended"
                        label="Ended"
                      ></v-text-field> -->
                      <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="workplan.w_ended"
                        label="Ended"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="workplan.w_ended"
                    @input="menu3 = false"
                    ></v-date-picker>
                </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6">
                      <v-text-field
                        v-model="workplan.expected"
                        label="Expected Beneficiaries"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="workplan.budget"
                        label="Budget"
                        type="number"
                        prefix="$"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="kushubo">
                  {{oper}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="editItem(item.id,item.serial_number,item.work_name,item.description,item.status,item.emp_id,item.started,item.ended,item.expected,item.budget)">
        <v-icon
          small
          class="mr-2">
          mdi-pencil
        </v-icon>
    </v-btn>
    <v-btn @click="deleteItem(item.id)">
        <v-icon
          small>
          mdi-delete
        </v-icon>
    </v-btn>
      </template>
      <template v-slot:no-data>
      
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      right
      color="brown">
      {{ text }}
    </v-snackbar>
    </v-container>
  </template>
 
  <script>
    import axios from 'axios'
    var url = 'http://localhost:88/workplan/crud.php';

    export default {
      data: () => ({
        loadTable: true,
        snackbar: false,
      text: `Hello, I'm a snackbar`,
        search:'',
        menu2: false,
        menu3:false,
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
            class:'brown white--text'
          },
          {text: 'Serial number', value: 'serial_number',class:'brown white--text',sortable:false},
          {text: 'Workplan', value: 'work_name',class:'brown white--text',sortable:false},
          {text: 'Description', value: 'description',class:'brown white--text',sortable:false},
          {text: 'Status', value: 'status',class:'brown white--text',sortable:false},
          {text: 'Assigned', value: 'assigned',class:'brown white--text',sortable:false},
          {text: 'Started', value:'started',class:'brown white--text',sortable:false},
          {text: 'Ended', value:'ended',class:'brown white--text',sortable:false},
          {text: 'Beneficiaries', value:'expected',class:'brown white--text',sortable:false},
          {text: 'Budget', value:'budget',class:'brown white--text',sortable:false},
          {text: 'Actions', value: 'actions', sortable: false,class:'brown white--text'},
        ],
        desserts: [],
        emp:[],
        workplan:{
            id:0,
            serial:'',
            wname:'',
            desc:'',
            st:'',
            emp_id:'',
            w_start:'',
            w_ended:'',
            expected:'',
            budget:''
        },
        opcion:'',
        editedIndex: -1,
        op:0,
        oper:'',
        operation:'',
        empoption:''
      }),
  
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'New Workplan' : 'Edit Workplan'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
       
        this.display();
        this.employeeoption();
      },
  
      methods: {
        empchange:function(event){
            this.workplan.emp_id=event;
            console.log(event);
        },
        employeeoption: function(){
            axios.post(url, { opcion: 3})
        .then(response => {
          this.empoption = response.data
           console.log(this.empoption)
           this.loadTable= false;
        }).catch(error => {
          console.log(error)
        })
        },
        display: function () {
            this.op=0;
      axios.post(url, { opcion: 2 ,op:this.op})
        .then(response => {
          this.emp = response.data
           console.log(this.emp)
        }).catch(error => {
          console.log(error)
        })
    },

        insert: function(){
            this.workplan.st='Planned';
        this.op=1;
      axios.post(url, { opcion: 2 ,op:this.op,serial:this.workplan.serial,wname:this.workplan.wname,desc:this.workplan.desc,st:this.workplan.st
        ,emp_id:this.workplan.emp_id,w_start:this.workplan.w_start,w_ended:this.workplan.w_ended,expected:this.workplan.expected,budget:this.workplan.budget})
        .then(response => {
           console.log( response.data);
           this.display();
           this.text='Insert Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        });
        this.dialog=false;
        },

        update: function(){
            this.op=11;
      axios.post(url, { opcion: 2, id:this.workplan.id,op:this.op,serial:this.workplan.serial,wname:this.workplan.wname,desc:this.workplan.desc,st:this.workplan.st
        ,emp_id:this.workplan.emp_id,w_start:this.workplan.w_start,w_ended:this.workplan.w_ended,expected:this.workplan.expected,budget:this.workplan.budget})
        .then(response => {
           console.log( response.data);
           this.display();
           this.text='Updated Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        })
        this.dialog=false;
        },

        kushubo:function(){
           if(this.operation=='insert'){
            this.insert();
           }else if(this.operation=='edit'){
            this.update();
           } 
        },

        delete: function(){

        },

        newdata:function(){
            this.operation='insert';
            this.oper='save';
            this.workplan.wname=''
            this.workplan.desc=''
            this.workplan.st=''
            this.workplan.emp_id=''
            this.workplan.w_start=''
            this.workplan.w_ended='';
            this.workplan.expected='';
            this.workplan.budget='';
        },
       
        editItem (id,serial,wname,desc,st,emp_id,w_start,w_ended,expected,budget) {
            this.operation='edit';
            this.oper='Update';
            this.workplan.id=id;
            this.workplan.serial=serial;
            this.workplan.wname=wname;
            this.workplan.desc=desc;
            this.workplan.st=st;
            this.workplan.emp_id=emp_id;
            this.workplan.w_start=w_start;
            this.workplan.w_ended=w_ended;
            this.workplan.expected=expected;
            this.workplan.budget=budget;
            // console.log(expected);
          this.dialog = true
        },
  
        deleteItem :function(id) {
            this.op=12;
      axios.post(url, { opcion: 2, id:id,op:this.op})
        .then(response => {
            console.log(id);
           console.log( response.data);
           this.display();
           this.text='Deleted Successfully';
           this.snackbar=true;
           setTimeout(() => {
            this.snackbar=false;
           }, 2000);
        }).catch(error => {
          console.log(error)
        })
        //   this.editedIndex = this.desserts.indexOf(item)
        //   this.editedItem = Object.assign({}, item)
          this.dialogDelete = true
        },
  
        deleteItemConfirm () {
          this.desserts.splice(this.editedIndex, 1)
          this.closeDelete()
        },
  
        close () {
          this.dialog = false
          // this.$nextTick(() => {
          //   this.editedItem = Object.assign({}, this.defaultItem)
          //   this.editedIndex = -1
          // })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
  
        save () {
          if (this.editedIndex > -1) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
          } else {
            this.desserts.push(this.editedItem)
          }
          this.close()
        },
      },
    }
  </script>