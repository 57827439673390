import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Dashboard.vue';
import AboutView from '../views/AboutView.vue';
import login from '../views/loginVue.vue';
import employee from '../views/employee.vue';
import workplan from '../views/workplan.vue';
import assign from '../views/assignwplan.vue';
import userdisplay from '../views/userdisplay.vue';
import report from '../views/report.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/employee',
    name: 'employee',
    component: employee
  },
  {
    path: '/workplan',
    name: 'workplan',
    component: workplan
  },
  {
    path: '/assign',
    name: 'assign',
    component: assign
  },
  {
    path: '/userdisplay',
    name: 'userdisplay',
    component: userdisplay
  },
  {
    path: '/report',
    name: 'report',
    component: report
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
