<template>
  <v-app>
    <div v-if="authenticated">
    <navigation />
  </div>
  <!-- <div v-if="authenticated===true && kaladooro==='User'">
    <usernav />
  </div> -->
    <v-main>
      <router-view @authenticated="setAuthenticated"/>
    </v-main>
    
  </v-app>
</template>

<script>
import navigation from './components/navigation.vue';
import usernav from './components/userDashboard.vue';
export default {
  components:{
    navigation,
    usernav
},
  name: 'App',

  data: () => ({
    //
    kaladooro:(sessionStorage.getItem('utype')),
    authenticated:JSON.parse(sessionStorage.getItem('status'))
   
  }),
  mounted(){
    if(!this.authenticated){
      this.$router.replace({name:'login'});
    }
    //console.log(this.kaladooro);
  },
  methods:{
   
    setAuthenticated(status){
      this.authenticated=status;
    }
  }
};
</script>
